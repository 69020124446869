body {
  background-color: #003366;
  color: #FFFFFF;
  font-weight: bold;
  line-height: calc(20px + 4vmin);
}
h2 {
  font-size: calc(10px + 4vmin);
  font-weight: bold;
  padding-top: 25px;
  padding-bottom: 25px;
}
div.header{
  padding-left: calc(10px + 1vmin);
}
div.row {
  background-color: #003366;
  padding-left: calc(10px + 1vmin);
  font-size: calc(10px + 1vmin);
}
.strikethrough {
  text-decoration: line-through;
  color: grey;
}
.pageContainer {
  padding-left: calc(10px + 2vmin);
}
.loading-text {
  color: yellow;
  padding-left: calc(10px + 1vmin);
}
